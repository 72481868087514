.economic-sector-bubble-map {
    padding: 20px;
    background: linear-gradient(to bottom, #0f2027, #203a43, #2c5364);
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
    color: #f0f8ff;
    text-align: center;
  }
  
  .economic-sector-bubble-map h2 {
    font-size: 2rem;
    color: #00d4ff;
    margin-bottom: 20px;
    text-shadow: 0px 4px 10px rgba(0, 212, 255, 0.5);
  }
  