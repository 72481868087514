/* src/components/Footer.css */
/* Chatbot Button */
#chatbot-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, #38bdf8, #2563eb); /* Matches app links */
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.3s ease-in-out, background 0.3s ease-in-out;
}

#chatbot-button:hover {
  transform: scale(1.1);
  background: linear-gradient(135deg, #2563eb, #1d4ed8); /* Slightly darker gradient */
}

#chatbot-button img {
  width: 30px;
  height: 30px;
  pointer-events: none; /* Ensure clicks don't affect the image */
}

/* Chatbot Window */
#chatbot {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 350px;
  max-width: 90%; /* Ensure responsiveness for smaller screens */
  height: 500px;
  max-height: 70%; /* Adjust height for smaller screens */
  background: #1e40af; /* Dark gradient for contrast with App background */
  color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: none;
  flex-direction: column;
  z-index: 1001;
  overflow: hidden;
}

/* Chatbot Header */
#chatbot-header {
  background: linear-gradient(135deg, #38bdf8, #2563eb); /* Matches app links */
  color: #ffffff; /* Bright white for contrast */
  padding: 10px;
  text-align: center;
  font-weight: bold;
  position: relative;
}

#chatbot-header button {
  position: absolute;
  top: 5px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  transition: transform 0.2s ease;
}

#chatbot-header button:hover {
  transform: scale(1.2);
  color: #38bdf8; /* Matches app link hover */
}

/* Chatbot Messages */
#chatbot-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  font-size: 0.9rem;
  line-height: 1.5;
  background: #1e3a8a; /* Matches App background */
  scrollbar-width: thin; /* For modern browsers */
  scrollbar-color: #38bdf8 #1e3a8a; /* Custom scrollbar colors */
}

#chatbot-messages::-webkit-scrollbar {
  width: 8px;
}

#chatbot-messages::-webkit-scrollbar-track {
  background: #1e3a8a;
}

#chatbot-messages::-webkit-scrollbar-thumb {
  background: #38bdf8;
  border-radius: 5px;
}

#chatbot-messages .message {
  margin-bottom: 10px;
  word-wrap: break-word; /* Ensure long messages don't overflow */
}

#chatbot-messages .user {
  text-align: right;
  color: #38bdf8; /* Matches app links */
}

#chatbot-messages .bot {
  text-align: left;
  color: white;
}

/* Loading Bubbles */
#loading-indicator {
  display: none; /* Hidden by default */
  text-align: center;
  margin: 10px 0;
}

#loading-indicator .dot {
  width: 8px;
  height: 8px;
  margin: 0 5px;
  background-color: #38bdf8; /* Matches app links */
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.2s infinite;
}

#loading-indicator .dot:nth-child(2) {
  animation-delay: 0.2s;
}

#loading-indicator .dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

/* Chatbot Input Section */
#chatbot-input {
  padding: 10px;
  display: flex;
  gap: 10px;
  background: #1e3a8a; /* Matches App header */
  align-items: center;
}

#chatbot-input textarea {
  flex: 1;
  resize: none;
  padding: 10px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #111827; /* Dark background for contrast */
  color: white;
  font-family: inherit;
  font-size: 0.9rem;
}

#chatbot-input button {
  background: #38bdf8; /* Matches app links */
  border: none;
  padding: 10px 15px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease, transform 0.2s ease;
}

#chatbot-input button:hover {
  background: #2563eb; /* Slightly darker */
  transform: scale(1.05);
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  #chatbot {
    bottom: 80px;
    right: 10px;
    width: 90%; /* Use most of the screen width */
    height: auto; /* Let height adjust dynamically */
    max-height: 80%; /* Limit max height */
  }

  #chatbot-header {
    font-size: 1rem; /* Adjust header font size */
  }

  #chatbot-input textarea {
    font-size: 0.8rem;
  }

  #chatbot-input button {
    padding: 8px 12px; /* Smaller padding for smaller screens */
    font-size: 0.9rem;
  }
}

.footer {
  background: var(--bg-secondary); /* Use the secondary dark blue from root variables */
  color: var(--light-text); /* Softer white for better readability */
  text-align: center;
  padding: 20px 10px; /* Increased padding for better spacing */
  font-size: 0.9rem; /* Slightly smaller font for footer */
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.3); /* Adds a subtle shadow at the top for depth */
  position: relative; /* Ensure the footer is within the normal flow */
  width: 100%;
  margin-top: auto; /* Push the footer to the bottom of the page content */
}

.footer p {
  margin: 0;
  line-height: 1.6;
}

.footer-link {
  color: var(--accent); /* Use your accent color for visibility */
  text-decoration: none; /* Remove default underline */
  transition: color var(--transition-speed) ease, text-decoration var(--transition-speed) ease;
}

.footer-link:hover,
.footer-link:focus {
  color: var(--accent-hover); /* Brighter color on hover for interactivity */
  text-decoration: underline;
}

/* Optional: Social Media Icons or Additional Links */
.footer .social-links {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.footer .social-links a {
  color: var(--light-text); /* Default icon color */
  font-size: 1.2rem; /* Adjust size as needed */
  transition: color var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.footer .social-links a:hover,
.footer .social-links a:focus {
  color: var(--accent); /* Change to accent color on hover/focus */
  transform: scale(1.1); /* Slightly enlarge on hover for interactivity */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .footer {
    padding: 15px 10px;
    font-size: 0.85rem;
  }

  .footer .social-links a {
    font-size: 1rem;
  }
}
