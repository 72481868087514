.chart-container {
    padding: 20px;
    background-color: #1e1e2f;
    border-radius: 15px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
    color: #fff;
    max-width: 800px;
    margin: 0 auto;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .chart-container:hover {
    transform: scale(1.01);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
  }
  
  .chart-title {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 1.8rem;
    color: #fff;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  
  .chart-loading, .chart-error {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    color: #fff;
  }
  
  .chart-error {
    color: #ff4d4d;
  }
  
  /* Legend customization */
  .chart-container .chartjs-legend li span {
    border-radius: 5px;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
  
  /* Add some hover effects on the legend (if using a custom legend) */
  .chart-container .chartjs-legend li:hover {
    opacity: 0.7;
  }
  
  /* Adjusting tooltips through CSS is limited since they are canvas-drawn, 
     but the Chart.js plugin options have been configured in JS. */
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .chart-container {
      padding: 15px;
    }
  
    .chart-title {
      font-size: 1.4rem;
    }
  }
  