/* General body styling */
body {
  background: linear-gradient(to bottom, #00274d, #00274d, #00274d);
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  color: #f0f8ff;
  overflow-x: auto;
}

/* Main dashboard container */
.dashboard-container {
  padding: 20px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

/* Header styling */
.dashboard-container h1 {
  font-size: 3rem;
  color: #00d4ff;
  text-shadow: 0px 4px 10px rgba(0, 212, 255, 0.502);
  margin-bottom: 20px;
}

/* Loading screen */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #00d4ff;
  font-size: 2rem;
}

/* Card component styling */
.card {
  padding: 25px;
  margin: 20px auto;
  max-width: 600px;
  background: rgba(255, 255, 255, 0.05);
  color: #f0f8ff;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  backdrop-filter: blur(15px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  transition: transform 0.4s, box-shadow 0.4s;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.6);
}

/* Motivational quote container */
.quote-container {
  margin: 30px 0;
  padding: 20px;
  background: linear-gradient(145deg, #1e3c72, #2a5298);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #f0f8ff;
  font-size: 1.2rem;
  font-style: italic;
}

/* Habit ideas container */
.habit-container ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

.habit-container li {
  padding: 10px 15px;
  background: #00d4ff;
  color: #00274d;
  border-radius: 10px;
  font-size: 1rem;
  transition: transform 0.3s, background 0.3s;
}

.habit-container li:hover {
  background: #f0f8ff;
  color: #00509e;
  transform: scale(1.1);
}

/* Habit generator container */
.habit-generator {
  margin: 40px auto;
  padding: 25px;
  max-width: 700px;
  background: rgba(255, 255, 255, 0.08);
  color: #f0f8ff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 1.2rem;
  transition: transform 0.3s, box-shadow 0.3s;
}

.habit-generator:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
}

/* Habit generator buttons */
.habit-generator button {
  background: linear-gradient(145deg, #00d4ff, #0084b4);
  color: #00274d;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, background 0.3s;
}

.habit-generator button:hover {
  background: linear-gradient(145deg, #0084b4, #00d4ff);
  transform: translateY(-3px);
}

/* Habit list styling */
.habit-generator ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin: 20px 0;
}

.habit-generator li {
  padding: 12px 18px;
  background: #00d4ff;
  color: #00274d;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s, background 0.3s;
}

.habit-generator li:hover {
  background: #f0f8ff;
  color: #00509e;
  transform: scale(1.1);
}

/* Habit details modal */
.habit-details-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(15, 32, 39, 0.95);
  padding: 30px;
  border-radius: 15px;
  color: #f0f8ff;
  max-width: 500px;
  width: 90%;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: none;
  animation: fadeIn 0.5s ease-in-out;
}

.habit-details-modal.active {
  display: block;
}

.habit-details-modal h2 {
  font-size: 1.8rem;
  color: #00d4ff;
  margin-bottom: 15px;
}

.habit-details-modal p {
  font-size: 1.2rem;
  line-height: 1.6;
}

.habit-details-modal button {
  background: linear-gradient(145deg, #ff6b6b, #ff4b4b);
  color: #f0f8ff;
  border: none;
  border-radius: 8px;
  padding: 10px 18px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s, transform 0.3s;
}

.habit-details-modal button:hover {
  background: linear-gradient(145deg, #ff4b4b, #ff6b6b);
  transform: translateY(-3px);
}

/* Overlay for modal */
.habit-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: none;
}

.habit-modal-overlay.active {
  display: block;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .habit-generator {
    padding: 20px;
    font-size: 1rem;
  }

  .habit-generator li {
    font-size: 0.9rem;
    padding: 10px 15px;
  }

  .habit-details-modal {
    padding: 20px;
  }

  .habit-details-modal h2 {
    font-size: 1.5rem;
  }

  .habit-details-modal p {
    font-size: 1rem;
  }
}

/* Education resources container */
.education-container {
  margin: 40px auto;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  padding: 30px;
  color: #f0f8ff;
  font-family: 'Roboto', sans-serif;
}

/* Main heading for education resources */
.education-container > h2 {
  font-size: 2rem;
  color: #00d4ff;
  text-shadow: 0px 4px 10px rgba(0, 212, 255, 0.5);
  margin-bottom: 30px;
  text-align: center;
}

/* Individual category blocks */
.education-category {
  margin-bottom: 40px;
}

/* Make category headers clickable */
.education-category h3 {
  font-size: 1.8rem;
  color: #00faff;
  margin-bottom: 15px;
  border-bottom: 2px solid #00d4ff;
  padding-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}

/* Resource List Styling */
.education-category ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.education-category li {
  margin: 12px 0;
  font-size: 1.2rem;
  position: relative;
  padding-left: 20px;
}

/* Subtle icon or bullet replacement (optional) */
.education-category li::before {
  content: "•";
  color: #00d4ff;
  position: absolute;
  left: 0;
  font-size: 1.5rem;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
}

/* Links to resources */
.education-container a {
  color: #00d4ff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.education-container a:hover {
  color: #00faff;
  text-shadow: 0 0 8px #00faff;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .education-container {
    padding: 20px;
  }

  .education-container > h2 {
    font-size: 1.8rem;
  }

  .education-category h3 {
    font-size: 1.5rem;
  }

  .education-category li {
    font-size: 1.1rem;
  }
}

/* Chart container */
.chart-container,
.live-chart-container {
  margin: 30px auto;
  padding: 20px;
  max-width: 90%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

/* Logout button styling */
.logout-button button {
  padding: 12px 25px;
  font-size: 1.1rem;
  color: #f0f8ff;
  background: linear-gradient(145deg, #ff6b6b, #ff4b4b);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, background 0.3s;
}

.logout-button button:hover {
  background: linear-gradient(145deg, #ff4b4b, #ff6b6b);
  transform: translateY(-3px);
}

/* Animation for smooth fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .dashboard-container h1 {
    font-size: 2.5rem;
  }

  .card {
    margin: 15px;
    padding: 20px;
  }

  .logout-button button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}
