.about-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #00274d, #00509e); /* Matches home page */
  color: #f0f8ff; /* Light text for readability */
  padding: 20px;
  box-sizing: border-box;
}

.about-container {
  max-width: 800px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent for modern design */
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.about-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #00274d; /* Contrasting dark blue for emphasis */
  margin-bottom: 20px;
}

.about-subheading {
  font-size: 1.8rem;
  font-weight: bold;
  color: #00509e; /* Vibrant blue for sections */
  margin-top: 30px;
  margin-bottom: 15px;
}

.about-text {
  font-size: 1.2rem;
  color: #333; /* Dark text for body */
  line-height: 1.6;
  margin-bottom: 20px;
}

.highlight {
  color: #00509e; /* Highlighted text for emphasis */
  font-weight: bold;
}

.about-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  text-align: left;
}

.about-list li {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.list-icon {
  font-size: 1.5rem;
  color: #00509e;
  margin-right: 10px;
}
