.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .login-container h1 {
    margin-bottom: 20px;
    color: #333; /* Dark heading */
  }
  
  .login-form .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333; /* Dark label text */
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: #d9534f; /* Bootstrap-like red for error messages */
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .register-redirect {
    margin-top: 20px;
    font-size: 14px;
    color: #333; /* Dark text for "Don't have an account?" */
  }
  
  .register-link {
    color: #007bff; /* Blue link for "Register here" */
    cursor: pointer;
    text-decoration: underline;
  }
  
  .register-link:hover {
    color: #0056b3; /* Darker blue on hover */
  }
  