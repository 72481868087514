/* Reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body Styling */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(135deg, #00274d, #00509e); /* Updated gradient background */
  background-color: #00274d; /* Fallback for older browsers */
  color: #fff; /* Light text for dark background */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  margin: 20px 0;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5); /* Subtle shadow for headers */
}

p {
  line-height: 1.8;
  font-size: 1rem;
  margin: 10px 0;
  text-align: center;
  color: #e0e0e0; /* Softer text color for body */
}

/* Links */
a {
  color: #ffcc00; /* Bright yellow links */
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #ffd633; /* Slightly brighter on hover */
  text-decoration: underline;
}

/* Containers */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  flex: 1; /* Ensures the container stretches to take up available space */
}

/* Header Styling */
header {
  background: transparent; /* Transparent header */
  padding: 20px 0;
  text-align: center;
  box-shadow: none; /* No shadow for a cleaner design */
}

header h1 {
  font-size: 2.5rem;
  color: #ffcc00;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

/* Footer Styling */
footer {
  margin-top: auto; /* Push footer to the bottom */
  background: #001f3f; /* Consistent with body background */
  text-align: center;
  padding: 15px 0;
  color: #e0e0e0;
  font-size: 0.9rem;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.3); /* Shadow at the top */
}

footer a {
  color: #ffcc00;
}

footer a:hover {
  text-decoration: underline;
}

/* Input and Button Styling */
input, button {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 12px 15px;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
}

input {
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
}

input:focus {
  border-color: #ffcc00;
  outline: none;
  box-shadow: 0px 0px 5px #ffcc00;
}

button {
  background: #ffcc00;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease;
}

button:hover {
  background: #ffd633;
}

.results {
  margin-top: 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05)); /* Subtle gradient for depth */
  padding: 25px; /* Slightly increased padding for a polished look */
  border-radius: 15px; /* Smooth curves for a modern aesthetic */
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4); /* Deeper shadow for a premium feel */
  border: 1px solid rgba(255, 255, 255, 0.3); /* A subtle border to define the section */
  backdrop-filter: blur(10px); /* Frosted glass effect for elegance */
  color: #ffffff; /* Ensures text is readable on the background */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Clean, modern font */
  font-size: 1rem; /* Clear text size */
  line-height: 1.6; /* Comfortable line spacing for readability */
}

.results h2 {
  color: #2563eb;
}

.results ul {
  list-style: none;
  padding: 0;
}

.results li {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 8px;
  text-align: center;
  transition: background 0.3s ease;
  color: #fff;
}

.results li:hover {
  background: rgba(0, 0, 0, 0.7);
}

/* Buttons in Results */
.results button {
  background: none;
  border: 1px solid #2563eb;
  color: #ffffff;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 0.9rem;
  transition: background 0.3s ease, color 0.3s ease;
}

.results button:hover {
  background: #2563eb;
  color: #000;
}
