.register-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.register-container h1 {
  margin-bottom: 20px;
  color: #333; /* Ensures the heading has sufficient contrast */
}

.register-form .form-group {
  margin-bottom: 15px;
  text-align: left;
}

.register-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333; /* Dark text for labels */
}

.register-form input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 14px;
  background-color: #fff; /* Ensure a clean white background */
  color: #333; /* Dark text for inputs */
}

.register-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #d9534f; /* Bootstrap-like red for error messages */
  margin-bottom: 15px;
  font-weight: bold;
}

.success-message {
  color: #5cb85c; /* Bootstrap-like green for success messages */
  margin-bottom: 15px;
  font-weight: bold;
}

.login-redirect {
  margin-top: 20px;
  font-size: 14px;
  color: #000; /* Explicit black for better visibility on white background */
}

.login-redirect span {
  color: #007bff; /* Link color for "Log in here" */
  cursor: pointer;
  text-decoration: underline;
}

.login-redirect span:hover {
  color: #0056b3; /* Darker blue on hover */
}
