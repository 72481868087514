.services-page {
  padding: 20px;
  background: linear-gradient(135deg, #00274d, #00509e); /* Background gradient */
  color: #f0f8ff; /* Light color for text contrast */
  min-height: 100vh; /* Ensures the page covers full height */
  box-sizing: border-box;
}

.services-header {
  text-align: center;
  margin-bottom: 40px;
}

.services-header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.services-header p {
  font-size: 1.2rem;
  color: #dcdcdc;
}

.services-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 0 20px;
}

.service-card {
  background: #ffffff;
  color: #333333;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.service-card h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #00509e; /* Vibrant color for service titles */
}

.service-card p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555555;
}
