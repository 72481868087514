.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #00274d, #00509e); /* Matches home page */
  padding: 20px;
  box-sizing: border-box;
}

.form-card {
  background: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  max-width: 600px;
  width: 100%;
  padding: 30px;
  text-align: center;
}

.form-heading {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-label {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
}

.form-input {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.2s;
}

.form-input:focus {
  border-color: #007bff;
}

.form-button {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  border: none;
  background-color: #007bff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.form-error {
  color: red;
  margin-top: 10px;
  font-weight: bold;
}

.results {
  margin-top: 30px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.results-heading {
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
}

.results-list {
  list-style: none;
  padding: 0;
}

.result-item {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  text-align: center;
  color: #007bff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.result-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
}
